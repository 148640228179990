.grp-holder {
    display: flex;
    justify-content: center;
    width: 320px;
    height: 400px;
    background-color: #a19ac3;
    position: relative;
    border-left: 6px solid #a19ac3;
    margin-bottom: 1rem;

    .walking-line {
        border-right: 6px solid #a19ac3;

        .win-coin {
            opacity: 1;
        }
    }

    .roadblock-holder {
        animation: none;
        top: 4rem;
    }

    .raccoon-preview {
        position: absolute;
        z-index: 2;
    }

    .bonus-info-holder {
        cursor: default;
    }

    &.jackpot-bar {
        .bonus-info-holder {
            top: 4rem;
        }
    }

    &.no-jackpot-bar {
        .bonus-info-holder {
            top: .5rem;
        }
    }
}

.grp-autoplay {
    width: 320px;
    margin-bottom: 1rem;

    .game-controls-manual {
        padding-bottom: 0;
    }

    .game-controls-manual-wrapper {
        padding: 1.5rem .5rem;
        border-radius: 0;
    }

    .input-holder input {
        padding-left: .5rem;
    }

    .gcmd-button {
        cursor: default;
    }

    .react-tabs {
        padding: 0;
    }
}

.grp-bet-history {
    &.bet-history-modal {
        padding: 0;
        background-color: #222d35;
        margin: 0;
        max-width: 100%;
        overflow: auto;
        margin-bottom: 1rem;
        max-width: 768px;

        .difficulty-info-title {
            background-color: #171e23;
            margin: 0;
            padding: 1rem 0.5rem;
            padding-left: 1rem;
        }

        .react-tabs__tab {
            color: white;
        }

        .bet-history-table-holder td {
            color: white;
        }

        .responsiveTable tbody tr:nth-child(even) {
            background-color: #2c3c48;
        }
    }
}

.game-controls-game-rules {
    margin-bottom: 0;
}

.game-rules-label {
    font-family: $font-regular;
    font-size: 12px;
    font-style: italic;
    margin-bottom: 2rem;
}

.game-controls-sound-screenshot {
    margin-bottom: 1rem;
}