.bonus-info-holder {
    position: absolute;
    top: 4rem;
    left: 50%;
    transform: translateX(-50%);
    background: rgb(255, 245, 38);
    background: linear-gradient(180deg, rgba(255, 245, 38, 1) 0%, rgba(223, 143, 1, 1) 100%);
    z-index: 5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .25rem 1rem;
    border-radius: 2rem;
    line-height: 1.2;
    height: 48px;
    min-width: 260px;
    font-family: $font-lilita;
    color: #1c262e;
    cursor: pointer;

    &.cumulative {
        height: 52px;

        .bonus-info-middle {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .bonus-rounds-value {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .bonus-rounds-expire {
            bottom: -.25rem;
            font-size: 8px;
        }
    }
}

.bonus-rounds-value {
    display: flex;
    align-items: baseline;
    line-height: 1;

    .value {
        font-size: 12px;
    }
}

.bonus-info-left {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
}

.bonus-rounds-text {
    line-height: .85;
    text-transform: uppercase;
}

.bonus-rounds-text,
.bonus-info-right {
    font-size: 16px;
}

.bonus-rounds-expire {
    position: absolute;
    bottom: -.25rem;
    font-size: 10px;
}

.bonus-info-middle {
    position: relative;
    height: 100%;
    padding: 0 0.5rem;
}

.bonus-info-win {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: $font-lilita;
}

.bonus-info-win-text {
    font-size: 10px;
    text-transform: uppercase;
}

.bonus-info-win-value {
    font-size: 16px;
}

.gradient-border {
    &.bonus-info-win {
      min-width: 100px;
      height: 32px;
      color: white;
      line-height: 1.1;
      padding: 0.25rem 0.5rem;
  
      &::before {
        top: 2px;
        right: 2px;
        bottom: 2px;
        left: 2px;
        background: rgb(27, 38, 45);
        background: linear-gradient(180deg, rgba(27, 38, 45, 1) 0%, rgba(25, 32, 37, 1) 100%);
      }
      &::after {
        @include boxShadow(inset 0 0 0 1px rgb(27, 38, 45));
        background: rgb(27, 38, 45);
        background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(25, 32, 37, 1) 100%);
      }
    }
  }

  .bonus-info-claim {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    border-radius: 2rem;
    background: white;
    z-index: 1;
    width: 100%;
    height: 32px;
    border: none;
    color: white;
    font-size: 1rem;
    font-family: $font-lilita;
    background: rgb(2, 247, 100);
    background: linear-gradient(180deg, rgba(2, 247, 100, 1) 0%, rgba(14, 126, 53, 1) 100%);
  }