.balance-wrapper {
  position: absolute;
  top: 400px;
  left: 0;
}

.balance-holder {
  position: absolute;
  bottom: 0.5rem;
  left: 0;
  background-color: rgba(23, 30, 35, 0.7);
  z-index: 5;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 0.35rem 1rem;
  border-radius: 0 2rem 2rem 0;
  line-height: 1.2;
//   height: 46px;
  min-width: 180px;
}
.balance {
  color: white;
  line-height: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: .5rem;

  .jackpot-odometer{
    cursor: default;
  }
  
}
.clock-holder {
    border-left: 1px solid #b2adc7;
    padding-left: 25px;
    padding-right: 0.5rem;
    flex-direction: column;
    align-items: center;
    line-height: 1;
}
