.game-rules-modal {
    .MuiPaper-root {
        background-color: #2c3c48;
    }

    header.MuiPaper-root {
        background-color: #171e23;
        position: fixed;
        min-height: 64px;
    }

    .MuiToolbar-root {
        min-height: 64px;
    }

    .MuiTypography-root {
        margin-left: 0;
    }
}


.game-rules-modal-wrapper {
    padding: 1rem;
    margin-top: 5rem;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        color: white;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: $font-semibold;
        margin-bottom: 1rem;
    }

    h5 {
        text-transform: uppercase;
    }

    p {
        font-family: $font-regular;
        text-align: justify;

        &.intro {
            color: #ffa800;
        }

        &.red {
            color: #ff3333;
        }
    }
}

.game-controls-sound-screenshot {
    width: 320px;
    padding-top: 1rem;
    background: #171e23;
    margin-bottom: 2rem;
}

.game-controls-sound-screenshot-badge {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    background-color: #ff3333;
    color: white;
    line-height: 1;
    position: absolute;
    top: -150%;
    left: 50%;
    margin-left: -4px;
}

.game-controls-footer-details {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 1.5rem;
    align-items: center;
    border-top: 4px solid #2c3c48;
    background: #171e23;
    height: 90px;
    position: relative;
}

.game-controls-game-rules {
    width: 100%;
    min-width: 320px;
    max-width: 330px;
    margin-bottom: 2rem;
    user-select: none;

    @media (min-width: $large) {
        max-width: 400px;
    }

    .react-tabs {
        padding-top: 10px;
        user-select: none;

        @media (min-width: $large) {
            padding-top: 20px;
        }
    }

    .react-tabs__tab {
        cursor: default;
        user-select: none;
    }
}

.game-controls-game-rules-border {
    width: 100%;
    height: 4px;
    background-color: #2c3c48;
    margin-bottom: .5rem;
}